import dayjs from 'dayjs';

export const parseErrors = (
	errors: any,
): {
	error?: string;
	errors: any;
	formErrors?: Record<string, any>;
} => {
	errors ??= {};
	errors = errors.data ?? errors.errors ?? errors;

	if (Array.isArray(errors) && errors.length > 0) {
		const parsed: Record<string, any> | string = {};
		let message = '';

		for (const error of errors) {
			const field = error.extensions?.field;

			message = error.message;

			if (field) {
				(parsed as Record<string, any>)[field] = error.message;
			}
		} // there are no validation errors
		if (Object.keys(parsed).length === 0) {
			return { error: message, errors };
		}

		return {
			formErrors: parsed,
			error: message,
			errors,
		};
	}

	//Zod error validation. Does not get included in the above statement. Comes back as undefined.
	if (
		errors.data?.issues &&
		Array.isArray(errors.data.issues) &&
		errors.data.issues.length > 0
	) {
		const parsed: Record<string, any> = {};
		let message = '';

		for (const issue of errors.data.issues) {
			const path = issue.path?.join('.') || 'general';

			message = issue.message;
			parsed[path] = issue.message;
		}

		return {
			formErrors: parsed,
			error: message,
			errors,
		};
	}

	return {
		error: errors,
		errors,
	};
};

// 👉 Min Length Validator
export const minLengthValidator = (
	value: unknown,
	length: number,
): boolean | string => {
	if (isEmptyValidator(value)) return true;

	return (
		String(value).length >= length ||
		`The field must be at least ${length} characters`
	);
};

// 👉 Max Length Validator
export const maxLengthValidator = (
	value: unknown,
	length: number,
): boolean | string => {
	if (isEmptyValidator(value)) return true;

	return (
		String(value).length <= length ||
		`The field must no more than ${length} characters`
	);
};

// 👉 IsEmpty
export const isEmptyValidator = (value: unknown): boolean => {
	if (value === null || value === undefined || value === '') return true;

	return !!(Array.isArray(value) && value.length === 0);
};

// 👉 Regex Validator
export const regexValidator = (
	value: unknown,
	regex: RegExp | string,
	message?: string,
): string | boolean => {
	if (isEmpty(value)) return true;

	let regeX = regex;
	if (typeof regeX === 'string') regeX = new RegExp(regeX);

	if (Array.isArray(value))
		return value.every(val => regexValidator(val, regeX));

	return (
		(regeX.test(String(value)) || message) ?? 'The field format is invalid'
	);
};

// 👉 Date between Validator
export const dateIsTodayValidator = (
	value: string,
	message?: string,
): string | boolean => {
	if (isEmpty(value)) return true;

	const date = dayjs(value);
	const today = dayjs();
	const startOfToday = today.startOf('day');
	const endOfToday = today.endOf('day');

	return (
		((date.isBefore(endOfToday) && date.isAfter(startOfToday)) || message) ??
		'Field needs to be a date time for today.'
	);
};

export const dateIsBeforeEndOfTodayValidator = (
	value: string,
	message?: string,
): string | boolean => {
	if (isEmpty(value)) return true;

	const date = dayjs(value);
	const today = dayjs();
	const endOfToday = today.endOf('day');

	return (
		(date.isBefore(endOfToday) || message) ??
		'Field needs to be a date time before today.'
	);
};
